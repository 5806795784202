'use strict';

(function() {
  function SiteResource($resource) {
    return $resource('api/v1/sites/:id/:controller/:page/:limit/:sortBy/:order', {
      id: '@_id'
    }, {
      update: {
        method: 'PUT'
      },
      getSitesWithoutPopulate: {
        method: 'GET',
        params: {
          id: 'without-populate'
        }
      },
      getTurbinesBySite: {
        method: 'GET',
        params: {
          controller: 'get-turbines'
        }
      },
    });
  }

  angular.module('windmanagerApp')
    .factory('Site', SiteResource);

})();
